<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.00011 0C6.59592 0 4.33585 0.936291 2.63646 2.63623C0.936259 4.33605 0 6.59624 0 8.99989C0 11.4035 0.936291 13.6638 2.63646 15.3635C4.33601 17.0637 6.59614 18 9.00011 18C11.4041 18 13.6644 17.0637 15.3638 15.3638C17.064 13.6638 18.0002 11.4038 18.0002 9.00011C18.0002 6.59647 17.0639 4.33618 15.3638 2.63646C13.6642 0.936259 11.4041 0 9.00011 0V0ZM14.5685 14.5681C13.0813 16.0556 11.1035 16.8749 9.00011 16.8749C6.89668 16.8749 4.91893 16.0556 3.43167 14.5681C1.9442 13.0808 1.12491 11.1034 1.12491 9.00002C1.12491 6.89658 1.94416 4.91915 3.43167 3.4319C4.91893 1.94442 6.89668 1.12513 9.00011 1.12513C11.1035 1.12513 13.0813 1.94439 14.5685 3.4319C16.056 4.91925 16.8753 6.89658 16.8753 9.00002C16.8753 11.1034 16.0561 13.0809 14.5685 14.5681Z" fill="#1E70BB"/>
    <path d="M14.0626 8.4375H3.93762C3.62712 8.4375 3.37512 8.68949 3.37512 9C3.37512 9.31051 3.62712 9.5625 3.93762 9.5625H14.0626C14.3731 9.5625 14.6251 9.31051 14.6251 9C14.6251 8.68949 14.3731 8.4375 14.0626 8.4375Z" fill="#1E70BB"/>
    <path d="M9.5625 14.0625L9.5625 3.9375C9.5625 3.62699 9.31051 3.375 9 3.375C8.68949 3.375 8.4375 3.62699 8.4375 3.9375L8.4375 14.0625C8.4375 14.373 8.68949 14.625 9 14.625C9.31051 14.625 9.5625 14.373 9.5625 14.0625Z" fill="#1E70BB"/>
  </svg>
</template>

<script>
export default {
  name: "IconAdd"
}
</script>